<template>
  <!-- Side Bar Menu -->
  <v-navigation-drawer class="nav_bg_color" expand-on-hover rail permanent location="left">
    <!-- Logo Menu ISAI -->
    <v-list class="list">
      <v-list-item class="background_logo">
        <v-img src="../images/isai.png"></v-img>
      </v-list-item>
    </v-list>
    <!-- Konten Menu -->
    <v-list nav>
      <v-divider class="divider"></v-divider>
      <router-link to="/beranda" class="menus">
        <v-list-item prepend-icon="mdi-home" value="beranda">Beranda</v-list-item>
      </router-link>
      <router-link to="/peta" class="menus">
        <v-list-item prepend-icon="mdi-map" value="maps">Peta</v-list-item>
      </router-link>
      <router-link to="/grafik_utama" class="menus">
        <v-list-item prepend-icon="mdi-chart-line" value="grafik">Grafik</v-list-item>
      </router-link>
      <router-link to="/kalkulator" class="menus">
        <v-list-item prepend-icon="mdi-calculator" value="kalkulator">Kalkulator Karbon</v-list-item>
      </router-link>
      <router-link to="/bantuan" class="menus">
        <v-list-item prepend-icon="mdi-help-circle" value="bantuan">Bantuan</v-list-item>
      </router-link>
      <v-divider class="divider"></v-divider>
      <router-link to="/masuk" class="menus">
        <v-list-item prepend-icon="mdi-account-edit" value="login">Masuk (Admin)</v-list-item>
      </router-link>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    items: [],
  }),
  methods: {

  },
};
</script>

<style>
.background_logo {
  background-color: #ffffff;
}

.menus {
  text-decoration: none;
  color: white;
  font-weight: bold;
  opacity: 0, 5;
  left: -30px;
}

.menus:hover {
  color: #000000;
}

.nav_bg_color {
  background-color: #134280;
}

.divider {
  padding: 2px;
  background-color: #fdfdfd;
}
.list {
  margin-top: -7px;
  margin-bottom: -20px;
}
</style>

