<template>
    <v-container style="width: 70%; height: 100%;">
        <v-breadcrumbs :items="items">
            <template v-slot:divider>
                <v-icon icon="mdi-chevron-right"></v-icon>
            </template>
        </v-breadcrumbs>

        <v-row>
            <v-col>
                <h2>
                    <v-icon icon="mdi-help-circle"></v-icon> Pusat Bantuan
                </h2>
            </v-col>
        </v-row>
        <v-img src="../assets/bantu.svg" width="45%" style="float: left  "> </v-img>
        <v-row>
            <v-col>
                <v-expansion-panels variant="accordion">
                    <v-expansion-panel>
                        <v-expansion-panel-title expand-icon="mdi-plus" collapse-icon="mdi-minus" class="panel">
                            <v-icon icon="mdi-information"></v-icon>&nbsp;&nbsp;[Tentang Karbon] Teori Karbon
                        </v-expansion-panel-title>
                        <v-expansion-panel-text class="bg_acc">
                            Dalam manusia karbon sekitar 20% dan ada beberapa karbon yang terdapat dalam tumbuhan.
                            Bagian yang termasuk dalam teori karbon
                            <ol>
                                <li>Komersial dan Destruktif</li>
                                <li>Penginderaan jauh (citra satelit)</li>
                                <span>NIR = radiasi inframerah dekat dari piksel
                                    <br>RED = radiasi cahaya merah dari piksel</span>
                                <li>Pemodelan</li>
                                <span>Citra drone -> Indeks spektral(X) -> Hasil analisis -> Karbon pada sampel
                                    tanaman(Y) -> Model alometrik</span>
                            </ol>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-title expand-icon="mdi-plus" collapse-icon="mdi-minus" class="panel">
                            <v-icon icon="mdi-information"></v-icon>&nbsp;&nbsp;Kebijakan Menu Masuk
                        </v-expansion-panel-title>
                        <v-expansion-panel-text class="bg_acc">
                            <div>
                                <ol>
                                    <li>Menu Masuk hanya diperuntukkan untuk admin</li>
                                    <li>Sign in dengan akun google hanya diperuntukkan bagi akun google yang sudah terdaftar (admin)</li>
                                </ol>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-title expand-icon="mdi-plus" collapse-icon="mdi-minus" class="panel">
                            <v-icon icon="mdi-chat-question"></v-icon>&nbsp;&nbsp;[Tentang Karbon] Mengapa Karbon Penting?
                        </v-expansion-panel-title>
                        <v-expansion-panel-text class="bg_acc">
                            <ol>
                                <li>Mampu mengevalusiasi suatu sektor untuk menghitung antara sarapan dan luaran karbon
                                </li>
                                <li>Mampu memaksimalkan perdagangan karbon</li>
                            </ol>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-title expand-icon="mdi-plus" collapse-icon="mdi-minus" class="panel">
                            <v-icon icon="mdi-chat-question"></v-icon>&nbsp;&nbsp;Apa Itu Kalkulator Karbon?
                        </v-expansion-panel-title>
                        <v-expansion-panel-text class="bg_acc">
                            <ul>
                                <li>Kalkulator Karbon merupakan kalkulator untuk menghitung jumlah karbon dari sebuah citra (gambar)</li>
                                <li>Kalkulator Karbon menggunakan algoritma tertentu untuk dapat menghitung serta menunjukkan perkiraan jumlah karbon</li>
                                <li>
                                    Cara Penggunaan:
                                    <ol>
                                        <li>
                                            Pilih menu Kalkulator Karbon
                                        </li>
                                        <li>
                                            Klik atau akses field (bidang) "Masukkan gambar"
                                        </li>
                                        <li>
                                            Pilih gambar yang ingin di unggah
                                        </li>
                                        <li>
                                            Kemudian pilih tombol "open"
                                        </li>
                                        <li>
                                            Tekan tombol "Unggah"
                                        </li>
                                    </ol>
                                </li>
                            </ul>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-title expand-icon="mdi-plus" collapse-icon="mdi-minus" class="panel">
                            <v-icon icon="mdi-chat-question"></v-icon>&nbsp;&nbsp;Cara Penggunaan Navigasi Pada Peta?
                        </v-expansion-panel-title>
                        <v-expansion-panel-text class="bg_acc">
                            <div>
                                <ul>
                                    <li>[Tanda Plus&nbsp;<v-icon icon="mdi-plus"/>]<br>Digunakan untuk membesarkan peta</li>
                                    <li>[Tanda Minus&nbsp;<v-icon icon="mdi-minus"/>]<br>Digunakan untuk mengecilkan peta</li>
                                    <li>[Kompas&nbsp;<v-icon icon="mdi-compass"/>]<br>Digunakan sebagai penanda arah mata angin</li>
                                    <li>[Search&nbsp;<v-icon icon="mdi-magnify"/>]<br>Digunakan untuk mencari lokasi tertentu</li>
                                </ul>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <br>
    </v-container>
</template>

<script>

export default {

    components: {

    },

    data: () => ({
        items: [
            'Dashboard',
            'Bantuan']
    })
}

</script>

<style>
.panel {
    color: white;
    background-color: #134280;
    font-size: 18px;

}

.bg_acc {
    background-color: white;
}
</style>