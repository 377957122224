<template>
    <v-container style="width: 70%; height: 100%;">
        <v-breadcrumbs :items="bread">
            <template v-slot:divider>
                <v-icon icon="mdi-chevron-right"></v-icon>
            </template>
        </v-breadcrumbs>
        <h2><v-icon icon="mdi-calculator"></v-icon>Kalkulator Karbon ISAI</h2>
        <div>
            <v-card variant="tonal" :elevation="isHovering ? 24 : 6" class="mx-auto pa-6 ma-4 rounded-card" style="height: 280px;">
                <v-form>
                    <v-row>
                        <v-col>
                            <v-file-input v-model="uploadPNG" @change="addPNG($event)" label="Masukkan gambar"
                                variant="filled" prepend-icon="mdi-camera" accept="image/png" required
                                style="width: 75%; margin: auto; margin-bottom: -40px;">
                            </v-file-input>
                        </v-col>
                    </v-row>
                </v-form>
                <div id="con">
                    <div style="margin-bottom: -10px;">
                        <v-btn type="submit" @click="submitForm"
                            style="background-color: rgba(0, 154, 56); color: white;">Unggah</v-btn>&nbsp;
                        <v-btn @click="reset" style="background-color:rgba(0, 113, 167); color: white;">Hapus</v-btn>
                    </div>
                    <br>
                    <div class="result" style="margin:auto:center">
                        <v-card-text>
                            <h5 style="display: flex; justify-content: center">
                                Nilai Karbon :&nbsp;<v-card class="mx-auto" width="100" height="30" variant="outlined"
                                    style="display: flex; justify-content: center;">{{ text }}
                                </v-card>
                            </h5>
                        </v-card-text>
                    </div>
                </div>
            </v-card>
        </div>
        <div>
            <div class="kiri">
                <v-timeline>
                    <v-timeline-item size="mini" elevation="10">
                        <template v-slot:icon>
                            <v-avatar><v-img src="../images/1.png"></v-img></v-avatar>
                        </template>
                        <template v-slot:opposite> </template>
                        <v-card elevation="10">
                            <v-card-title class="text-h6 font-light text-black">
                                Pilih gambar yang Anda inginkan
                            </v-card-title>
                            <v-card-text class="txt">
                                Tekan "Masukkan Gambar" atau tombol kamera yang tersedia. Gambar harus memiliki ekstensi
                                .PNG
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                </v-timeline>

                <v-timeline>
                    <v-timeline-item size="mini" elevation="10">
                        <template v-slot:icon>
                            <v-avatar><v-img src="../images/2.png"></v-img></v-avatar>
                        </template>
                        <template v-slot:opposite> </template>
                        <v-card elevation="10">
                            <v-card-title class="text-h6 font-light text-black ">
                                Tekan tombol "Unggah"
                            </v-card-title>
                            <v-card-text class="txt">
                                Dengan menekan tombol "Unggah", gambar akan dikirim untuk diproses oleh machine learning
                                yang akan menghitung prediksi karbon di gambar tersebut.
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                </v-timeline>

                <v-timeline>
                    <v-timeline-item size="mini" elevation="10">
                        <template v-slot:icon>
                            <v-avatar><v-img src="../images/3.png"></v-img></v-avatar>
                        </template>
                        <template v-slot:opposite> </template>
                        <v-card elevation="10">
                            <v-card-title class="text-h6 font-light text-black ">
                                Tunggu nilai karbon
                            </v-card-title>
                            <v-card-text class="txt">
                                Hasil prediksi nilai karbon membutuhkan beberapa waktu untuk bisa ditampilkan. Jadi,
                                jangan
                                panik, hasilnya pasti akan keluar!
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                </v-timeline>
            </div>

            <div class="kanan">
                <v-timeline>
                    <v-timeline-item size="mini" elevation="10">
                        <template v-slot:icon>
                            <v-avatar><v-img src="../images/4.png"></v-img></v-avatar>
                        </template>
                        <template v-slot:opposite> </template>
                        <v-card elevation="10">
                            <v-card-title class="text-h6 font-light text-black ">
                                Lihat nilai karbon
                            </v-card-title>
                            <v-card-text class="txt">
                                Hasil prediksi nilai karbon akan ditampilkan di dalam box kecil yang bertuliskan "Nilai
                                Karbon"
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                </v-timeline>

                <v-timeline>
                    <v-timeline-item size="mini" elevation="10">
                        <template v-slot:icon>
                            <v-avatar><v-img src="../images/5.png"></v-img></v-avatar>
                        </template>
                        <template v-slot:opposite> </template>
                        <v-card elevation="10">
                            <v-card-title class="text-h6 font-light text-black ">
                                Hapus nilai karbon</v-card-title>
                            <v-card-text class="txt">
                                Sebelum meninggalkan halaman, jangan lupa untuk menekan tombol "Hapus", ya!
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                </v-timeline>
            </div>
        </div>
    </v-container>
</template> 

<style>
#con {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px;
}

.kiri {
    float: left;
    width: 50%;
}

.kanan {
    float: right;
    width: 50%;
}
</style>

<script>

import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

var uuid = uuidv4();
export default {
    data: function () {
        return {
            items: {
                uuid: null,
                uploadPNG: ''
            },
            text: sessionStorage.getItem('karbonTanah'),
            bread: [
                'Dashboard',
                'Kalkulator Karbon']
        }
    },
    methods: {
        addPNG: function (event) {
            this.uploadPNG = event.target.files[0];
        },
        submitForm: async function () {
            this.uuid = String(uuid);
            let binary = await this.readBinary(this.uploadPNG)


            const s3 = 'https://nl227f95td.execute-api.us-east-1.amazonaws.com/dpl/kalkulator-isai/' + this.uuid + '.png';
            const url = 'https://nl227f95td.execute-api.us-east-1.amazonaws.com/dpl/kalkulator/' + this.uuid + '.png';

            let bytes = new Uint8Array(binary.length);
            for (let i = 0; i < binary.length; i++) {
                bytes[i] = binary.charCodeAt(i);
            }
            const contentType = 'image/png';
            let file = new Blob([bytes], { type: contentType });
            axios.post(s3, file, { headers: { 'Content-Type': 'image/png' } });
            setTimeout(function () {
                axios.get(url)
                    .then((resp) => {
                        sessionStorage.setItem('karbonTanah', resp.data.body.karbon_tanah);
                        location.reload();
                    });
            }, 500);
        },
        readBinary: function (file) {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = function () {
                    resolve(reader.result)
                }
                reader.onerror = reject
                reader.readAsBinaryString(file)
            })
        },
        reset: function () {
            sessionStorage.removeItem('karbonTanah');
            location.reload();
        }
    }
}
</script>