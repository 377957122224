import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import router from "./router";
import "bootstrap/dist/css/bootstrap.min.css";

import { initializeApp } from "firebase/app";
const firebaseConfig = {
    apiKey: "AIzaSyBpnvxuzTIOh4OVTrEBIFsFNkBcDoTZFrI",
    authDomain: "kedaireka-d4ad3.firebaseapp.com",
    projectId: "kedaireka-d4ad3",
    storageBucket: "kedaireka-d4ad3.appspot.com",
    messagingSenderId: "274924105585",
    appId: "1:274924105585:web:ff10021e941a26354b50d0",
};

// Initialize Firebase
initializeApp(firebaseConfig);
loadFonts();

createApp(App).use(vuetify).use(router).mount("#app");
