<template>
    <v-container style="width: 70%; height: 100%;" id="elem">
        <v-breadcrumbs :items="items">
            <template v-slot:divider>
                <v-icon icon="mdi-chevron-right"></v-icon>
            </template>
        </v-breadcrumbs>
        <v-row>
            <v-col>
                <router-link to="/halaman_admin" style="text-decoration: none; float: left; margin-left: 15px;">
                    <v-btn style="background-color: rgba(0, 154, 56); color: white;">Kembali</v-btn>
                </router-link>
            </v-col>
            <v-col style="text-align: center">
                <p id="alert">Halaman ini tidak akan bisa di akses setelah 1 jam</p>
            </v-col>
            <v-col>
                <v-btn @click="logout" style="float: right; margin-right: 15px; background-color: rgba(0, 113, 167);color: white;">
                    Keluar
                </v-btn>
            </v-col>
        </v-row>

        <FormCreate/>

    </v-container>
</template>

<script>
import FormCreate from "./FormCreate.vue";
import { useRouter } from "vue-router";

export default {
    data() {
        return {
            items: [
                'Dashboard',
                'Masuk (Admin)',
                'Halaman Admin',
                'Menu Buat'
            ]
        }
    },
    setup() {
        const router = useRouter();
        const logout = () => {
            localStorage.setItem("authenticated", false);
            router.push("/masuk")
        };
        return { logout };
    },
    components: {
        FormCreate
    }
};
</script>

<style lang="scss" scoped>
#alert {
    font-size: 14px;
}
</style>