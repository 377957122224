import { createRouter, createWebHistory } from 'vue-router'
import Beranda from "@/components/MenuBeranda.vue";
import Maps from "@/components/MenuMaps.vue";
import Bantuan from "@/components/MenuBantuan.vue";
import Login from "@/components/MenuLogin.vue";
import Admin_Page from "@/components/AdminPage.vue";
import Dashboard from "@/components/MenuDashboard.vue";
import Visual_Page from "@/components/VisualPage.vue";
import CreateMenu from '@/components/CreateMenu.vue';
import Kalkulator from '@/components/KalkulatorKarbon.vue';
import MainGraph from '@/components/MainGraph.vue';

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: "/beranda",
    name: "beranda",
    component: Beranda,
  },
  {
    path: "/peta",
    name: "maps",
    component: Maps,
  },
  {
    path: "/bantuan",
    name: "bantuan",
    component: Bantuan,
  },
  {
    path: "/masuk",
    name: "login",
    component: Login,
  },
  {
    path: "/grafik_titik/:id",
    name: "visual_page",
    component: Visual_Page
  },
  {
    path: "/halaman_admin",
    name: "admin_page",
    component: Admin_Page,
  },
  {
    path: "/menu_buat",
    name: "create_menu",
    component: CreateMenu
  },
  {
    path: "/kalkulator",
    name: "kalkulator",
    component: Kalkulator
  },
  {
    path: "/grafik_utama",
    name: "main_graph",
    component: MainGraph
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, _from, next) => {
  const isAuthenticated = JSON.parse(localStorage.getItem('authenticated'));
  setTimeout(() => {
    localStorage.removeItem('authenticated');
    location.reload();
  }, 3600000);
  if (to.name !== "dashboard" && to.name !== "beranda" && to.name !== "maps" && to.name !== "bantuan" && to.name !== "login" && to.name !== "visual_page" && to.name !== "main_graph" && to.name !== "kalkulator" && to.name !=="loading" && !isAuthenticated) next({ name: "login" });
  else if (to.name == "login" && isAuthenticated) next({ name: "admin_page" });
  else next();
})

export default router
